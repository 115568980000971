// @flow strict
import React from 'react';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import styles from './Sidebar.module.scss';
import { useSiteMetadata } from '../../hooks';

// import LOGO_IMG from "../../../static/icon2-logo2.png"

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const { author, copyright, menu } = useSiteMetadata();
  const action_url = '//www.google.com/search'
  const blog_url = 'https://kashiwaba-yuki.com/'
  const method_get = 'get'
  const type_search = 'search'
  const type_submit = 'submit'
  const type_hidden = 'hidden'
  const name_q = 'q'
  const name_sitesearch = 'sitesearch'
  const placeholder_search = 'Search'


  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__inner']}>
        {/* <div className={styles['logo-image']}>
          <img src={LOGO_IMG} className={styles['headerimage']} alt="logo"></img>
        </div> */}
        <Author author={author} isIndex={isIndex} />
        <Menu menu={menu} />
        <form action={action_url} method={method_get} className={styles['search-container']}>
          <input type={type_search} name={name_q} placeholder={placeholder_search} className={styles['search-container-input']} />
          <button type={type_submit} className={styles['search-container-submit']}></button>
          <input type={type_hidden} name={name_sitesearch} value={blog_url} />
        </form>
        <Contacts contacts={author.contacts} />
        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export default Sidebar;
